import { createSelector } from "@reduxjs/toolkit";

import { selectInputDevices } from "@carescribe/audio/src/reducers/audio";

export const selectInputDeviceOptions = createSelector(
  selectInputDevices,
  (devices) =>
    devices.map(({ deviceId, label }) => ({
      display: label,
      value: deviceId,
    }))
);
