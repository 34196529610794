import type { EventChannel, SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { eventChannel } from "redux-saga";
import { takeEvery, call, put, select } from "redux-saga/effects";

import { getMicrophonePermissionState } from "./utils";
import {
  selectPermissionState,
  setInputDevices,
  setPermissionState,
} from "../reducers/audio";

const createPermissionsEventChannel = (): EventChannel<PermissionState> =>
  eventChannel<PermissionState>((emit) => {
    const abortController = new AbortController();

    navigator.permissions
      .query({ name: "microphone" as PermissionName })
      .then((status) => {
        status.addEventListener(
          "change",
          ({ target }) => emit((target as PermissionStatus).state),
          abortController
        );
      })
      .catch((e) => {
        console.error(`Microphone Permission Error: ${e}`);
        emit("denied");
      });

    return () => abortController.abort();
  });

/**
 * Keeps the microphone permission state state up to date.
 *
 * These are refreshed:
 * - at the start
 * - whenever the user grants or revokes microphone permissions
 * - whenever the list of devices is updated
 *   This is necessary for Safari, where `PermissionStatus: change event` does
 *   not fire when the user grants microphone permissions.
 */
export const setUpTrackMicrophonePermissions =
  function* (): SagaIterator<void> {
    const permissionState: SagaReturnType<typeof getMicrophonePermissionState> =
      yield call(getMicrophonePermissionState);
    yield put(setPermissionState(permissionState));

    const permissionsEventChannel: SagaReturnType<
      typeof createPermissionsEventChannel
    > = yield call(createPermissionsEventChannel);

    yield takeEvery(
      permissionsEventChannel,
      function* (permissionState): SagaIterator<void> {
        yield put(setPermissionState(permissionState));
      }
    );

    yield takeEvery(setInputDevices, function* (): SagaIterator<void> {
      const previousPermissionState: SagaReturnType<
        typeof selectPermissionState
      > = yield select(selectPermissionState);

      const permissionState: SagaReturnType<
        typeof getMicrophonePermissionState
      > = yield call(getMicrophonePermissionState);

      /**
       * Devices list is also refreshed whenever `setPermissionState` actions
       * are detected. And so, this is necessary in order to avoid getting
       * into an infinite loop.
       */
      const hasChanged = previousPermissionState !== permissionState;

      if (hasChanged) {
        yield put(setPermissionState(permissionState));
      }
    });
  };
