import type { DeviceInfo, Frequencies } from "../types";
import type { PayloadAction } from "@reduxjs/toolkit";

import { createSlice, createSelector } from "@reduxjs/toolkit";

type AudioState = {
  inputDevices: DeviceInfo[];
  permissionState: PermissionState;
  selectedInputDeviceId: string | null;
  volume: number;
  frequencies: Frequencies;
};

type CombinedState = {
  audio: AudioState;
};

const frequencies: Frequencies = {
  subBass: 0,
  bass: 0,
  lowMids: 0,
  mids: 0,
  highMids: 0,
  presence: 0,
};

const initialState: AudioState = {
  frequencies,
  inputDevices: [],
  permissionState: "prompt",
  selectedInputDeviceId: null,
  volume: 0,
};

const setPermissionStateReducer = (
  state: AudioState,
  { payload }: PayloadAction<PermissionState>
): void => {
  state.permissionState = payload;
};

const setInputDevicesReducer = (
  state: AudioState,
  { payload }: PayloadAction<DeviceInfo[]>
): void => {
  state.inputDevices = payload;
};

const setSelectedInputDeviceIdReducer = (
  state: AudioState,
  { payload }: PayloadAction<string | null>
): void => {
  state.selectedInputDeviceId = payload;
};

const setVolumeReducer = (
  state: AudioState,
  { payload }: PayloadAction<number>
): void => {
  state.volume = payload;
};

const setFrequenciesReducer = (
  state: AudioState,
  { payload }: PayloadAction<Frequencies>
): void => {
  state.frequencies = payload;
};

const resetFrequenciesReducer = (state: AudioState): void => {
  state.frequencies = frequencies;
};

const slice = createSlice({
  name: "audio",
  initialState: initialState,
  reducers: {
    resetFrequencies: resetFrequenciesReducer,
    setFrequencies: setFrequenciesReducer,
    setInputDevices: setInputDevicesReducer,
    setPermissionState: setPermissionStateReducer,
    setSelectedInputDeviceId: setSelectedInputDeviceIdReducer,
    setVolume: setVolumeReducer,
  },
});

export const {
  resetFrequencies,
  setFrequencies,
  setInputDevices,
  setPermissionState,
  setSelectedInputDeviceId,
  setVolume,
} = slice.actions;

export const reducer = slice.reducer;

export const selectInputDevices = (rootState: CombinedState): DeviceInfo[] =>
  rootState.audio.inputDevices;

export const selectPermissionState = (
  rootState: CombinedState
): PermissionState => rootState.audio.permissionState;

export const selectIsMicrophonePermissionGranted = createSelector(
  [selectPermissionState],
  (permissionState): boolean => permissionState === "granted"
);

export const selectSelectedInputDevice = (
  rootState: CombinedState
): string | null => rootState.audio.selectedInputDeviceId;

export const selectSelectedInputDeviceName = createSelector(
  [selectInputDevices, selectSelectedInputDevice],
  (inputDevices, selectedDeviceId): string | null => {
    const selectedDevice =
      selectedDeviceId !== null
        ? inputDevices.find((device) => device.deviceId === selectedDeviceId)
        : null;
    return selectedDevice?.label ?? null;
  }
);

export const selectVolume = (rootState: CombinedState): number =>
  rootState.audio.volume;

export const selectFrequencies = (rootState: CombinedState): Frequencies =>
  rootState.audio.frequencies;
