import { createSelector, createSlice } from "@reduxjs/toolkit";

type State = {
  openPreferences: number;
};

type CombinedState = {
  hotkeys: {
    openPreferences: number;
  };
};

const slice = createSlice({
  name: "hotkeys",
  initialState: {
    openPreferences: 0,
  },
  reducers: {
    openPreferences: (state) => {
      // just need a value that changes
      // a number will serve our purposes
      state.openPreferences += 1;
    },
  },
});

export const {
  reducer,
  actions: { openPreferences },
} = slice;

const selectHotKeys = (state: CombinedState): State => state.hotkeys;

export const selectOpenPreferences = createSelector(
  [selectHotKeys],
  (hotkeys) => hotkeys.openPreferences
);
