import type { RootState } from "@talktype/store";
import type { MiniSoundEqualiserProps } from "@talktype/ui/src/DictationButton/MiniSoundEqualiser";

import { createSelector } from "@reduxjs/toolkit";
import { connect } from "react-redux";

import {
  selectFrequencies,
  selectVolume,
} from "@carescribe/audio/src/reducers/audio";

import { MiniSoundEqualiser as Component } from "@talktype/ui/src/DictationButton/MiniSoundEqualiser";

export const selectFrequencyBars = createSelector(
  selectFrequencies,
  selectVolume,
  (frequencies, volume): { id: string; height: string }[] => {
    const { bass, lowMids, mids, highMids, subBass, presence } = frequencies;

    const selectedFrequencies = [
      { id: "highMids", value: highMids },
      { id: "lowMids", value: lowMids },
      { id: "subBass", value: subBass },
      { id: "mids", value: mids },
      { id: "bass", value: bass },
      { id: "presence", value: presence * 1.5 },
    ];

    const calculateHeight = (level: number, volume: number): string => {
      const volumeFactor = 0.4;
      const adjustedLevel = level * (1 + volumeFactor * (volume / 255));
      return `${(adjustedLevel / 255) * 100}%`;
    };

    return selectedFrequencies.map(({ id, value }) => ({
      id,
      height: calculateHeight(value, volume),
    }));
  }
);

const mapStateToProps = (
  state: RootState
): Pick<MiniSoundEqualiserProps, "bars"> => ({
  bars: selectFrequencyBars(state),
});

export const MiniSoundEqualiser = connect(mapStateToProps)(Component);
